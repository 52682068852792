import * as React from 'react';
import ProfileCircle from '../common/ProfileCircle';
import MomentHelper from '../../helpers/MomentHelper';
import { useNavigate } from 'react-router-dom';
import AppImageContainer from './AppImageContainer';
import App from '../../model/appManagement/App';
import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

const AppManagementCard = ({ app }: AppManagementCardProps) => {
    const navigate = useNavigate();
    const intl = useIntl();

    const getCreatedByDate = () => {
        const formattedDate = MomentHelper.newInstance(app.createdDate).format('ll');
        return (
            <span className="mr-2">
                <b>{`${intl.formatMessage({ id: 'Common.Created' })}`}&#58;</b>&nbsp;{`${formattedDate}`}
            </span>
        );
    };

    return (
        <div className="app-management-card" onClick={() => navigate(`/Apps/${app.appId}`)}>
            <div className="d-flex flex-row p-3 align-items-center created-by">
                {getCreatedByDate()}
                <div>
                    <ProfileCircle fullName={app.createdByUser} />
                </div>
            </div>
            <div className="icon-container justify-content-center d-flex">
                <AppImageContainer application={app} />
            </div>
            <Grid spacing={1} sx={{ m: 1 }} container justifyContent="space-between">
                <Grid item>
                    <h4>{app.title}</h4>
                </Grid>
                <Grid spacing={1} xs={12} item container>
                    <Grid item>
                        <small className="rd-tag green font-so">{app!.apiType}</small>
                    </Grid>
                    {!app.isSettingsConfigured && (
                        <Grid item>
                            <Tooltip
                                title={intl.formatMessage({
                                    id: 'AppManagement.AppDetails.SettingsNotConfiguredMessage',
                                })}
                                arrow
                                placement="top"
                            >
                                <span>
                                    <InfoIcon color="warning"></InfoIcon>
                                </span>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export interface AppManagementCardProps {
    app: App;
}

export default AppManagementCard;

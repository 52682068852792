import * as React from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import App from '../../../model/appManagement/App';
import { FormattedMessage, useIntl } from 'react-intl';
import appStatus from '../../../enums/appSubmissionStatus';
import Tag from '../../common/Tag';
import textDisplay from '../../../helpers/TextDisplayHelper';
import LoadingSpinner from '../../common/LoadingSpinner';
export interface AppsTableProps {
    apps?: App[];
    isLoading?: boolean;
}

const AppsTable = (props: AppsTableProps) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const getStatusName = (status: any) => {
        switch (status) {
            case appStatus.notSubmitted:
                return intl.formatMessage({ id: 'Admin.AppList.NotSubmittedStatus' });
            case appStatus.approved:
                return intl.formatMessage({ id: 'Admin.AppList.ApprovedStatus' });
            case appStatus.rejected:
                return intl.formatMessage({ id: 'Admin.AppList.PendingStatus' });
            case appStatus.listingApproved:
                return intl.formatMessage({ id: 'Admin.AppList.ListingApprovedStatus' });
            case appStatus.listingRejected:
                return intl.formatMessage({ id: 'Admin.AppList.ListingRejectedStatus' });
            case appStatus.actionsRequired:
                return intl.formatMessage({ id: 'Admin.AppList.ActionsRequiredStatus' });
            case appStatus.accountClosed:
                return intl.formatMessage({ id: 'Admin.AppList.AccountClosedStatus' });
            case appStatus.listingLive:
                return intl.formatMessage({ id: 'Admin.AppList.ListingLiveStatus' });
            case appStatus.listingSubmitted:
                return intl.formatMessage({ id: 'Admin.AppList.ListingSubmittedStatus' });
            default:
                return textDisplay.formatPascalText(status);
        }
    };

    return (
        <div>
            {props.isLoading ? (
                <LoadingSpinner />
            ) : (
                <Fragment>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ backgroundColor: '#e5e9f0' }}>
                                <TableRow>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.AppNameCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.IdCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.OrganisationsCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.ApiCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.StatusCol" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.apps &&
                                    props.apps.map((app, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() => navigate(`${app.appId}/${app.organisationId}`)}
                                        >
                                            <TableCell className="btn btn-link">{app.title}</TableCell>
                                            <TableCell>{app.appId}</TableCell>
                                            <TableCell>{app.organisationName}</TableCell>
                                            <TableCell>{app.apiType}</TableCell>
                                            <TableCell>
                                                <Tag
                                                    value={getStatusName(app.submissionStatus)}
                                                    color={app.statusTag == null ? 'Grey' : app.statusTag}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fragment>
            )}
        </div>
    );
};
export default AppsTable;

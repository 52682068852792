import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import config from './config/app';
import AppContextProvider from './contexts/AppContextProvider';
import {
    AccountQuestionnairePage,
    ApiDocumentationDetail,
    AuthenticationPage,
    CreateAccountPage,
    ForgotPasswordPage,
    OrganisationSetupPage,
    JoinTeamPage,
    LandingPage,
    Layout,
    LoginPage,
    AppLogDetails,
    PageNotFound,
    ResetPasswordPage,
    TeamMembersPage,
    VerifyAccountPage,
    GeneralSettingsPage,
    ProfileLandingPage,
    RolesPage,
    AppManagementPage,
    //  AppNavigationBasePage,
    AdminLandingPage,
    AdminAppListPage,
} from './components';
import TestDiariesPage from './components/testDiaries/TestDiariesPage';
import Admin from './pages/Admin';
import TestClientApplicationRoutes from './components/testClientApplication';
import ProtectedRoute from './utils/ProtectedRoute';
import PublicRoute from './utils/PublicRoute';
import OrganisationSettingsPage from './components/profile/OrganisationSettingsPage';
import AppDetailsPage from './components/appManagement/AppDetailsPage';
import ActivateAccountPage from './components/account/ActivateAccountPage';
export default function App() {
    return (
        <AppContextProvider>
            <Layout>
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route path={config.landingPath} element={<LandingPage />} />
                        <Route path="Authentication" element={<AuthenticationPage />} />{' '}
                        {/* ^ old authentication page from ResDiary Hub V1 */}
                        <Route path="AppLogDetails/:id" element={<AppLogDetails />} />{' '}
                        {/* ^ need to confirm if this route is still needed */}
                        <Route path={config.appsListPath} element={<AppManagementPage />} />
                        <Route path={config.appDetailsPath} element={<AppDetailsPage />} />
                        <Route path={config.documentationsPath} element={<ApiDocumentationDetail />} />
                        <Route path={config.onboardingOrganisationSetupPath} element={<OrganisationSetupPage />} />
                        <Route path={config.onboardingQuestionnairePath} element={<AccountQuestionnairePage />} />
                        <Route path={config.profileLandingPath} element={<ProfileLandingPage />} />
                        <Route path={config.profileGeneralSettingsPath} element={<GeneralSettingsPage />} />
                        <Route path={config.profileTeamMembersPath} element={<TeamMembersPage />} />
                        <Route path={config.profileRolesPath} element={<RolesPage />} />
                        <Route path={config.testDiariesPath} element={<TestDiariesPage />} />
                        <Route path={config.profileOrganisationSettingsPath} element={<OrganisationSettingsPage />} />
                        <Route path={config.teamLandingPath} element={<TeamMembersPage />} />
                    </Route>
                    <Route element={<ProtectedRoute isAdminRoute={true} />}>
                        <Route path={config.adminLandingPath} element={<AdminLandingPage />} />
                        <Route path={config.adminAppsPath} element={<AdminAppListPage />} />
                        <Route path={config.adminAppDetailsPath} element={<AppDetailsPage />} />
                        <Route path={config.adminDocumentsPath} element={<ApiDocumentationDetail />} />
                        <Route path={config.adminAuditPath} element={<Admin.AuditTrailPage />} />
                        <Route path={config.adminDocumentsPath} element={<Admin.DocumentManagementPage />} />
                        <Route path={config.adminTestDiariesPath} element={<Admin.TestDiaryListPage />} />
                        <Route path={config.adminOrganisationsPath} element={<Admin.OrganisationListPage />} />
                        <Route path={config.adminUsersPath} element={<Admin.UserManagementPage />} />
                    </Route>
                    <Route element={<PublicRoute isRestricted={true} />}>
                        <Route path={config.loginPath} element={<LoginPage />} />
                        <Route path={config.createAccountPath} element={<CreateAccountPage />} />
                        <Route path={config.resetPasswordPath} element={<ResetPasswordPage />} />
                        <Route path={config.forgotPasswordPath} element={<ForgotPasswordPage />} />
                    </Route>
                    <Route element={<PublicRoute />}>
                        <Route path={config.joinTeamPath} element={<JoinTeamPage />} />
                        <Route path={config.joinAdminTeamPath} element={<JoinTeamPage />} />{' '}
                        {/* ^ TODO: Need to consolidate the admin and hub user JoinTeamPage logic */}
                        <Route path={config.verifyAccountPath} element={<VerifyAccountPage />} />
                        <Route path={config.activateAccountPath} element={<ActivateAccountPage />} />
                        <Route path={config.testAppPath} element={<TestClientApplicationRoutes />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                </Routes>
            </Layout>
        </AppContextProvider>
    );
}

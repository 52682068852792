const app = {
    joinTeamPath: '/Accounts/JoinTeam',
    loginPath: '/Accounts/Login',
    activateAccountPath: '/Accounts/Activate',
    createAccountPath: '/Accounts/Register',
    verifyAccountPath: '/Accounts/Verify',
    forgotPasswordPath: '/ForgotPassword',
    resetPasswordPath: '/ResetPassword',
    organisationSetupPath: '/Accounts/OrganisationSetup',
    accountQuestionnairePath: '/Accounts/Questionnaire',

    landingPath: '/',
    appsListPath: '/Apps',
    appDetailsPath: '/Apps/:appId',
    profileLandingPath: '/Profile',
    profileGeneralSettingsPath: '/Profile/GeneralSettings',
    profileOrganisationSettingsPath: '/Profile/OrganisationSettings',
    profileRolesPath: '/Profile/Organisation/Roles',
    profileTeamMembersPath: '/Profile/Organisation/TeamMembers',
    testDiariesPath: '/Testing/TestDiaries',
    documentationsPath: '/Docs/:productId?/:categoryId?/:articleId?',
    onboardingOrganisationSetupPath: '/Onboarding/OrganisationSetup',
    onboardingQuestionnairePath: '/Onboarding/Questionnaire',
    teamLandingPath: '/Team',

    // Admin routes
    adminLandingPath: '/Admin',
    adminAppsPath: '/Admin/Apps',
    adminAppDetailsPath: '/Admin/Apps/:appId/:organisationId',
    adminOrganisationsPath: '/Admin/Organisations',
    adminTestDiariesPath: '/Admin/TestDiaries',
    adminSubscriptionsPath: '/Admin/Subscriptions',
    adminUsersPath: '/Admin/Users',
    adminDocumentsPath: '/Admin/Documents',
    adminAuditPath: '/Admin/AuditTrail',
    joinAdminTeamPath: '/Admin/Accounts/JoinTeam',

    testAppPath: '/TestApp/*',

    // string constants?
    prodDisplayName: 'Production',
    testDisplayName: 'Test',

    authenticationPath: 'Auth/SignIn', // <-- This might no longer be needed
} as const;

export default app;
